/* 예: Quill 에디터의 내용 스타일을 변경 */
.ql-editor {
    font-size: 14px;
    width: 600px;
  }

  /* .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid rgb(74, 74, 74);
}

  .ql-container.ql-snow {
    border: 1px solid rgb(74, 74, 74);
}

  .ql-toolbar {
    display: none;
  } */
