.rdw-option-wrapper {
    min-width: 10px !important;
}

.rdw-fontsize-dropdown {
    min-width: 20px !important;
}

.rdw-fontsize-wrapper > div {
    width: 35px !important;
    font-size: 10px !important;
}

.rdw-fontsize-wrapper > div > a > img {
    width: 15px !important;
}

.rdw-fontsize-wrapper > div > a > div{
    right: 1% !important;
}



.toolbar {
    /* border: solid 1px red; */
    
    /* height: 20px; */
}

.toolbar > div {
    display: flex;
}


/* .wrapper {
    margin-top: 10px;
} */

.editor {
    padding-left: 5px;
    border: solid 1px black;
    height: 300px;
    width: 770px !important;
}

