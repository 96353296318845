/* 예: Quill 에디터의 내용 스타일을 변경 */
.ql-editor {
    font-size: 14px;
    width: 690px !important;
    height: 215px;
    /* border: solid 2px red; */
  }

  .ql-container.ql-snow {
    /* border: 1px solid red; */
    height: 220px;
  }
  
  /* .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid rgb(74, 74, 74);
}

  .ql-container.ql-snow {
    border: 1px solid rgb(74, 74, 74);
}

  .ql-toolbar {
    display: none;
  } */
