.lastDepositAmount{
    background: rgb(188, 124, 124) !important;
    color: white !important;
}

.lastDepositAmounttd{
    background: rgb(188, 124, 124) !important;
    opacity: 0.6;
    color: white !important;
}

.bonusTyperolling{
    background: rgb(188, 124, 124) !important;
    color: white !important;
}

.bonusTyperollingtd{
    background: rgb(188, 124, 124) !important;
    opacity: 0.6;
    color: white !important;
}

.totalBettingAmounts{
    background: rgb(188, 124, 124) !important;
    color: white !important;
}

.totalBettingAmountstd{
    background: rgb(188, 124, 124) !important;
    opacity: 0.6;
    color: white !important;
}

.currentRollingRatio{
    background: rgb(188, 124, 124) !important;
    color: white !important;
}

.currentRollingRatiotd{
    background: rgb(188, 124, 124,0.6) !important;
    color: red !important;
}

.liveProfit{
    background: green !important;
    color: white !important;
}

.rollingLivetotal{
    background: green !important;
    color: white !important;
}

.liveProfittd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.makedResult{
    background: green !important;
    color: white !important;
}

.makedResulttd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.calculatePointAmonut{
    background: green !important;
    color: white !important;
}

.calculatePointAmonuttd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.changePointAmount{
    background: red !important;
    color: white !important;
}

.changePointAmounttd{
    background: red !important;
    opacity: 0.6;
    color: white !important;
}

/* 지인콤프 */
.friendCompMaxAmount{
    background: rgb(0, 104, 86) !important;
    color: white !important;
}

.friendCompMaxAmounttd{
    background: rgb(0, 104, 86) !important;
    opacity: 0.6;
    color: white !important;
}
.totalFriendCompAmount{
    background: green !important;
    color: white !important;
}

.totalFriendCompAmounttd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.finalFriendCompAmount{
    background: red !important;
    color: white !important;
}

.finalFriendCompAmounttd{
    background: red !important;
    opacity: 0.6;
    color: white !important;
}

.slotProfit{
    background: orange !important;
    color: white !important;
}

.rollingSlottotal{
    background: orange !important;
    color: white !important;
}

.slotProfittd{
    background: orange !important;
    opacity: 0.6;
    color: white !important;
}

.sportsGameProfit{
    background: purple !important;
    color: white !important;
}


.rollingSportsGametotal{
    background: purple !important;
    color: white !important;
}

.sportsGameProfittd{
    background: purple !important;
    opacity: 0.6;
    color: white !important;
}


.miniGameProfit{
    background: pink !important;
    color: white !important;
}


.rollingMiniGametotal{
    background: pink !important;
    color: white !important;
}

.miniGameProfittd{
    background: pink !important;
    opacity: 0.6;
    color: white !important;
}

.bettingAmount {
    background: green !important;
    color: white !important;
}

.bettingAmounttd {
    background: rgba(0, 128, 0, 1) !important;
    opacity: 0.6;
    color: white !important;
}


.bettingAmountCasino {
    color: red !important;
}

.bettingAmountCasinotd {
    color: red !important;
}

.losingAmount {
    background: green !important;
    color: white !important;
}

.losingAmounttd {
    background: rgba(0, 128, 0, 1) !important;
    opacity: 0.6;
    color: white !important;
}

.whatBettingtd{
    background: rgba(237, 222, 88,0.5) !important;
    opacity: 0.6;
    color: #945b04 !important;
    font-weight: 900;
}

.gameNametd {
    background: rgba(237, 222, 88,0.5) !important;
    opacity: 0.6;
    color: #945b04 !important;
    font-weight: 900;
}
.gameTypeCasinotd {
    background: rgba(237, 222, 88,0.5) !important;
    opacity: 0.6;
    color: #945b04 !important;
    font-weight: 900;
}

.miniGameTypetd{
    background: rgba(237, 222, 88,0.5) !important;
    opacity: 0.6;
    color: #945b04 !important;
    font-weight: 900;
}
.miniGameBettingTypetd{
    background: rgba(237, 222, 88,0.5) !important;
    opacity: 0.6;
    color: #945b04 !important;
    font-weight: 900;
}

.marketNametd{
    background: rgba(237, 222, 88,0.5) !important;
    opacity: 0.6;
    color: #945b04 !important;
    font-weight: 900;
}

.profit{
    background: blue !important;
    color: white !important;
}

.profittd{
    background: blue !important;
    opacity: 0.6;
    color: white !important;
}

.rolling{
    background: green !important;
    color: white !important;
}

.rollingtd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.bettingAmountCasino{
    background: green !important;
    color: white !important;
}

.changePointExchangetd {
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.bettingAmountCasinotd{
    background: rgb(123, 207, 123) !important;
    /* opacity: 0.6; */
    color: white !important;
}

.rollingAmount{
    background: green !important;
    color: white !important;
}

.rollingAmounttd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.rollingAmounts{
    background: rgb(0,104,86) !important;
    color: white !important;
}

.rollingAmountstd{
    background: rgb(0,104,86,0.6) !important;
    color: white !important;
}

.changeAmountlog{
    background: green !important;
    color: white !important;
}

.changeAmountlogtd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}



.applyMoneywithdrawal{
    background: green !important;
    color: white !important;
}

.applyMoneywithdrawaltd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.applyMoneyMember {
    background: green !important;
    color: white !important;
}

.applyMoneyMembertd {
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.changeAmountMoneyExchange{
    background: green !important;
    color: white !important;
}

.changeAmountMoneyExchangetd {
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.changePointDeposit{
    background: green !important;
    color: white !important;
}

.changePointDeposittd{
    background: rgb(86, 167, 83, 0.8) !important;
    opacity: 1;
    color: white !important;
}

.changeCompDeposittd {
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.changePointExchange{
    background: green !important;
    color: white !important;
}

.changeCompExchangetd {
    background: green !important;
    opacity: 0.6;
    color: white !important;
}


.changePointPartner{
    background: green !important;
    color: white !important;
}

.changePointPartnertd {
    background: green !important;
    opacity: 0.6;
    color: white !important;
}


.realMoney{
    background: green !important;
    color: white !important;
}

.realMoneytd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}

.changeAmountinc{
    background: green !important;
    color: white !important;
}

.changeAmountinctd{
    background: green !important;
    opacity: 0.6;
    color: white !important;
}


.mySlotRolling{
    background: blue !important;
    color: white !important;
}
.totalProfit{
    background: blue !important;
    color: white !important;
}


.changeAmounttd {
    font-weight: 900;
}

.myLiveRolling{
    background: blue !important;
    color: white !important;
}

.myMiniGameRolling{
    background: blue !important;
    color: white !important;
}

.mySportsGameRolling{
    background: blue !important;
    color: white !important;
}

.rollingSlot{
    background: blue !important;
    color: white !important;
}

.rollingLive{
    background: blue !important;
    color: white !important;
}
.rollingMiniGame{
    background: blue !important;
    color: white !important;
}
.rollingSportsGame{
    background: blue !important;
    color: white !important;
}

.losing{
    background: blue !important;
    color: white !important;
}

.myLosingAmount{
    background: blue !important;
    color: white !important;
}

.mySubLosingAmount{
    background: blue !important;
    color: white !important;
}

/* .losingtd{
    background: blue !important;
    opacity: 0.6;
    color: white !important;
} */